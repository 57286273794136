// @ts-nocheck

import axios, {AxiosInstance} from 'axios';


const webApp = window?.Telegram?.WebApp;

export const API_URL: string = `${process.env.REACT_APP_BACKEND_API_URL}`;
export const API_TELEGRAM_WEBHOOK: string = `${process.env.REACT_APP_BACKEND_TELEGRAM_WEBHOOK_URL}`;

const $api: AxiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
      // 'ngrok-skip-browser-warning': true,
    }
});

$api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `token ${token}`;
  }
  return config;
});

$api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
        webApp.showPopup({message: `Error: ${error.request}`});
    }
    return error;
  }
)


export default $api;