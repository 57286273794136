import { useDispatch, useSelector } from 'react-redux';
import type {TypedUseSelectorHook} from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import auth from './auth/slice';
import chats from './chats/slice';
import support from './support/slice';
import profile from './profile/slice';
import purchase from './purchase/slice';
import packages from './packages/slice';
import payments from './payments/slice';
import promocode from './promocode/slice';
import documents from './documents/slice';
import myServices from './my-services/slice';
import invitecode from './invite-code/slice';

export const store = configureStore({
    reducer: {
        auth,
        chats,
        support,
        profile,
        purchase,
        packages,
        payments,
        promocode,
        documents,
        invitecode,
        myServices,
    },
})

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;