import './assets/css/App.scss';

// Router
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Redux
import { ReduxProvider } from './redux/provider';

// Layouts
import {ChatLayout, HomeLayout, HomeWithoutFooter} from './layouts/home';

// Pages
import Chat from './pages/chat/Chat';
import Chats from './pages/chats/Chats';
import AuthPage from './pages/auth/Auth';
import HomePage from './pages/home/Home';
import Profile from './pages/profile/Profile';
import Service from './pages/service/Service';
import AreasLaw from './pages/areasLaw/areasLaw';
import Services from './pages/services/Services';
import WelcomePage from './pages/weclome/Welcome';
import SupportPage from './pages/support/Support';
import MyAppeals from './pages/MyAppeals/MyAppeals';
import LegalInfo from './pages/legalInfo/LegalInfo';
import MyDocuments from './pages/MyDocuments/MyDocuments';
import ServicesPay from './pages/services-pay/ServicesPay';
import ServiceInfo from './pages/services-info/ServiceInfo';
import InviteFriend from './pages/inviteFriend/InviteFriend';


function App() {
  return (
    <ReduxProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/auth" element={<AuthPage />} />

          <Route path='/home' element={<HomeLayout />}>
            <Route index element={<HomePage />}/>
            <Route path='services' element={<Services />}/>
            <Route path='chats' element={<Chats />}/>
            <Route path='profile' element={<Profile />}/>
          </Route>

          <Route path='/home' element={<HomeWithoutFooter />}>
            <Route index path='areas-law' element={<AreasLaw />} />
            <Route path='service-info' element={<ServiceInfo />} />
            <Route path='my-documents' element={<MyDocuments />} />
            <Route path='my-appeals' element={<MyAppeals />} />
            <Route path='support' element={<SupportPage />} />
            <Route path='legal-info' element={<LegalInfo />} />
            <Route path='service-pay/:alias/:type' element={<ServicesPay />} />
            <Route path='service/:serviceId' element={<Service />} />
            <Route path='invite-friend' element={<InviteFriend />} />
          </Route>

          <Route path='/home' element={<ChatLayout />}>
            <Route path='chat/:chatId' element={<Chat />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;
