import style from './ServicesPay.module.scss';

// React
import { useEffect, useState } from 'react';

// Ant Design
import { message as antdMessage } from 'antd';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { setPackageByAlias } from '../../redux/packages/slice';
import { selectPackages } from '../../redux/packages/selectors';
import { PromoCodeFormData, Status } from '../../redux/promocode/types';
import { requestActivatePromoCode } from '../../redux/promocode/asyncActions';

// Telegram
import { MainBtn } from '../../components/utils/buttons/Buttons';

// Route
import { useNavigate, useParams } from 'react-router-dom';

// VK
import vkBridge from '@vkontakte/vk-bridge';

// Components
import Spinner from '../../components/utils/spinner/Spinner';
import { setPromocodeIsNull } from '../../redux/promocode/slice';
import { selectPromoCode } from '../../redux/promocode/selectors';
import { TelegramBackBtn } from '../../components/utils/buttons/Buttons';
import { GetUserInfo, TypeBtn } from '../../types/types';
import { selectPurchase } from '../../redux/purchase/selectors';
import { PurchaseFormData } from '../../redux/purchase/types';
import { requestPurchase } from '../../redux/purchase/asyncActions';
import { setPurchaseIsNull } from '../../redux/purchase/slice';


const ServicesPay = () => {
    let { alias, type } = useParams();
    const navigate = useNavigate();
    const [promocode, setPromocode] = useState<string>('');

    // User
    const [user, setUser] = useState<GetUserInfo | void>();

    useEffect(() => {
        if (!user) {
            vkBridge.send('VKWebAppGetUserInfo')
                .then((data) => {
                    if (data.id) {
                        setUser({
                            id: data.id,
                            firstName: data.first_name,
                            lastName: data.last_name,
                        })
                    }
                })
        }
    }, [user])

    // Redux
    const dispatch = useAppDispatch();
    const { activePackage } = useSelector(selectPackages);

    useEffect(() => {
        if (alias && type) {
            dispatch(setPackageByAlias({
                alias: alias,
                type: type,
            }))
        }
    }, [dispatch, alias, type])

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();
    const { status, message, promo_package } = useSelector(selectPromoCode);

    // Activate Promocode
    const handlerActivatePromocode = (e: any) => {
        e.preventDefault();
        e.target.disabled = true;

        if (promo_package) {
            messageApi.info('Вы уже активировали промокод', 2);
            e.target.disabled = false;
            return;
        }

        if (promocode.length === 0) {
            messageApi.error('Пожалуйста введите промокод', 2);
            e.target.disabled = false;
            return;
        }
 
        if (alias) {
            const formData: PromoCodeFormData = {
                promocode: promocode,
                service: alias,
            }
            dispatch(requestActivatePromoCode(formData));
        }
        e.target.disabled = false;
    }

    useEffect(() => {
        if (status !== Status.CALM && message.length > 0) {
            if (status === Status.SUCCESS) {
                messageApi.success(message, 2);
                return;
            }
            if (status === Status.ERROR) {
                messageApi.error(message, 2);
                return;
            }
        }
    }, [status, message, messageApi])

    // Purchase
    const { messagePurchase, statusPurchase } = useSelector(selectPurchase);

    // Payment Handler
    const payment_handler = () => {
        if (activePackage && user?.id) {
            vkBridge.send('VKWebAppOpenPayForm', {
                'app_id': 52071705,
                "action": "pay-to-user",
                "params": {
                    // amount: promo_package ? promo_package.price : activePackage.price,
                    amount: 1,
                    user_id: 638746546,
                }
            })
            .then((data: any) => {
                console.log('data: ', data);
                if (data.result) {
                    console.log('data.result: ', data.result);
                    // Обработка события в случае успеха
                    const formDataPurchase: PurchaseFormData = {
                        package_alias: activePackage.alias,
                        vkontakte_id: user.id,
                    }
                    dispatch(requestPurchase(formDataPurchase));
                } else {
                    // Ошибка
                    alert(`Ошибка в data.result: ${data.result}`);
                }
            })
            .catch((error: any) => {
                console.log('STATUS: error', error);
                // Ошибка
                alert(`
                    type: ${error.type},
                    error_type: ${error.data.error_type},
                `);
            })
        }
    }

    useEffect(() => {
        if (statusPurchase === Status.SUCCESS && messagePurchase.length > 0) {
            messageApi.success(messagePurchase, 2);
            navigate(`/home/`);
        }

        if (statusPurchase === Status.ERROR && messagePurchase.length > 0) {
            messageApi.error(messagePurchase, 2);
        }

        return () => {
            dispatch(setPurchaseIsNull());
            dispatch(setPromocodeIsNull());
        }
    }, [statusPurchase, messagePurchase, messageApi, dispatch])
    
    return (
        <div className={style.service_pay}>
            {contextHolder}
            <TelegramBackBtn url='home/service-info' />
            {!activePackage
                ? (
                    <Spinner />
                )
                : (
                    <>
                        <div className={style.title}>
                            <h1>Покупка Услуги</h1>
                        </div>
                        <div className={style.service_pay__name}>
                            <h2>Услуга {activePackage.verbose_name}</h2>
                        </div>
                        <div className={style.service_pay__price}>
                            <p>Стоимость</p>
                            <p>{promo_package ? promo_package.price : activePackage?.price} ₽</p>
                        </div>
                        <div className={style.service_pay__promocode}>
                            <p>Есть промокод?</p>
                            <form>
                                <input
                                    type="text"
                                    value={promocode}
                                    onChange={(e) => setPromocode(e.target.value)} />
                                <button onClick={handlerActivatePromocode}>Применить</button>
                            </form>
                        </div>
                    </>
                )}
                <div className={style.service_pay__btn}>
                    <MainBtn onClickAction={payment_handler} type={TypeBtn.Button} text='Перейти к оплате' />
                </div>
        </div>
    )
}

export default ServicesPay;