import style from './Welcome.module.scss';

// React
import { useEffect, useState } from 'react';

// Redux
import { useAppDispatch } from '../../redux/store';
import { Profile } from '../../redux/profile/types';
import { requestAuth } from '../../redux/auth/asyncActions';

// VK
import vkBridge from '@vkontakte/vk-bridge';

// Images
import iconHeader from '../../assets/img/Слой 2.svg';
import iconBackHeader from '../../assets/img/Vector.svg';
import iconCheckMark from '../../assets/img/Component 14.svg';

// Components
import { MainBtnText } from '../../components/utils/buttons/Buttons';
import { useNavigate } from 'react-router-dom';
import { GetUserInfo, TypeBtn } from '../../types/types';


const WelcomePage = () => {
    const [user, setUser] = useState<GetUserInfo | void>();
    const [isClearLocalStorage, setIsClearLocalStorage] = useState<boolean>(false);

    // Redux
    const dispatch = useAppDispatch();

    // Get Token
    if (!isClearLocalStorage) {
        localStorage.clear();
        setIsClearLocalStorage(true);
    }
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!user) {
          vkBridge.send('VKWebAppGetUserInfo')
          .then((data) => { 
            if (data.id) {
                setUser({
                    id: data.id,
                    firstName: data.first_name,
                    lastName: data.last_name,
                })
            }    
          })
        }
      }, [user])

    useEffect(() => {
        if (!token && user?.id) {
            // Request Get Token
            const formData: Profile = {
                'vkontakte_id': user.id ? `${user.id}` : null,
                'first_name': user.firstName ? user.firstName : null,
                'last_name': user.lastName ? user.lastName : null,
            }
            dispatch(requestAuth(formData));
        }
    }, [token, dispatch, user])

    // Handle Click Continue
    const navigate = useNavigate();

    const handleContinueBtn = () => {
        navigate('/home');
    }

    return (
        <div className={style.welcome_info}>
            <div className={style.welcome_info__img}>
                <img src={iconBackHeader} alt="back-header" />
                <img src={iconHeader} alt="icon-lawyers" />
            </div>
            <div className={style.welcome_info__services}>
                <ul>
                    <li>
                        <img src={iconCheckMark} alt="check-mark" />
                        <p><span className={style.important}>Стоимость</span> услуги от 499 руб.</p>
                    </li>
                    <li>
                        <img src={iconCheckMark} alt="check-mark" />
                        <p>Консультируем <span className={style.important}>24/7</span> по телефону.</p>
                    </li>
                    <li>
                        <img src={iconCheckMark} alt="check-mark" />
                        <p><span className={style.important}>Подарок</span> за каждого приглашенного друга.</p>
                    </li>
                    <li>
                        <img src={iconCheckMark} alt="check-mark" />
                        <p>Быстро и <span className={style.important}>конфиденциально</span></p>
                    </li>
                </ul>
            </div>
            <div className={style.welcome_info__btn}>
                <MainBtnText text='Продолжить' type={TypeBtn.Button} onClickAction={handleContinueBtn} />
            </div>
        </div>
    )
}

export default WelcomePage;