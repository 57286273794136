// Router
import { Outlet } from 'react-router-dom'

// Components
import HeaderHome from '../components/Home/Header/Header';
import FooterHome from '../components/Home/Footer/Footer';
import { BackAdvaBtn } from '../components/utils/buttons/Buttons';


export function HomeLayout() {
  return (
    <>
      <HeaderHome />
      <Outlet />
      <FooterHome />
    </>
  )
}


export function HomeWithoutFooter() {
  return (
    <>
      <HeaderHome />
      <BackAdvaBtn />
      <Outlet />
    </>
  )
}

export function ChatLayout() {
  return (
    <>
      <Outlet />
    </>
  )
}