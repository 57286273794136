import style from './Home.module.scss';

// React
import { useEffect, useState } from 'react';

// Router
import { Link } from 'react-router-dom';

// VK
import vkBridge from '@vkontakte/vk-bridge';

// Redux
import { useSelector } from 'react-redux';
import { Status } from '../../redux/auth/types';
import { useAppDispatch } from '../../redux/store';
import { selectAuth } from '../../redux/auth/selectors';
import { selectInviteCode } from '../../redux/invite-code/selectors';
import { getInviteReferralFormData } from '../../redux/invite-code/types';
import { requestGetInviteReferral } from '../../redux/invite-code/asyncActions';

// Images
import shadowBack from '../../assets/img/shadow_background.svg';
import vetPetImg from '../../assets/img/our-services/verPet.svg';
import astarsImg from '../../assets/img/our-services/astars.svg';
import priceInvite from '../../assets/img/invite-friend/price.svg';
import consultationIcon from '../../assets/img/consultations/1.svg';
import virtualPsychologyImg from '../../assets/img/our-services/virtualPsychology.svg';

// Components
import { Consultation, ConsultationAll } from '../../components/Home/Consultation/Consultation';

// Types
import { GetUserInfo, serviceInfo } from '../../types/types';


const HomePage = () => {
    const [user, setUser] = useState<GetUserInfo | void>();

    useEffect(() => {
        if (!user) {
          vkBridge.send('VKWebAppGetUserInfo')
          .then((data) => { 
            if (data.id) {
                setUser({
                    id: data.id,
                    firstName: data.first_name,
                    lastName: data.last_name,
                })
            }    
          })
        }
    }, [user])

    // Get Referral Code
    const dispatch = useAppDispatch();

    const { status } = useSelector(selectAuth);
    const { refferal_code } = useSelector(selectInviteCode);

    useEffect(() => {
        if (status === Status.SUCCESS && !refferal_code && user) {
            const formData: getInviteReferralFormData = {
                vkontakte_id: user.id
            }
            dispatch(requestGetInviteReferral(formData));
        }
    }, [dispatch, user, status, refferal_code])

    return (
        <main>
            <img src={shadowBack} className={style.shadow_back} alt="shadow-back" />
            <div className={style.info_services}>
                <h1>ONLINE - КОНСУЛЬТАЦИЯ ЮРИСТА</h1>
                <div className={style.consultations}>
                    {serviceInfo.slice(0, 5).map((elem) => (
                        <Consultation
                            key={elem.id}
                            text={elem.name}
                            link={elem.query}
                            img={consultationIcon}
                        />
                    ))}
                    <ConsultationAll />
                </div>
            </div>
            <div className={style.info_invite_friend}>
                <Link to={'/home/invite-friend'}>
                    <p>Приведи друга и получи подарок!</p>
                    <img src={priceInvite} alt="price-icon" />
                </Link>
            </div>
            <div className={style.info_our_services}>
                <h1>Наши Сервисы</h1>
                <div className={style.services_block}>
                    <a target='_blank' href="https://vet-pet.online/" rel="noreferrer">
                        <img src={vetPetImg} alt="vet-pet" />
                    </a>
                    <a target='_blank' href="https://astars.online/" rel="noreferrer">
                        <img src={astarsImg} alt="astars" />
                    </a>
                    <a target='_blank' href="https://vipsy.online/" rel="noreferrer">
                        <img src={virtualPsychologyImg} alt="virtual-psychology" />
                    </a>
                </div>
            </div>
        </main>
    )
}

export default HomePage;