export enum Status {
    CALM = 'calm',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface CreateFeedbackFormData {
    vkontakte_id: string | null;
    username: string | null;
    message: string;
}

export interface SupportSliceState {
    status: Status | string;
    message: string;
}